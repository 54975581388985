"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCourseCompletedFromProgress = void 0;
const { get } = require('lodash');
function isCourseCompletedFromProgress({ progress, item, currentCourseTab, shareId, courseId }) {
    var _a;
    if (progress.isLoading) {
        return false;
    }
    const courseProgress = get(progress, `${shareId}.${courseId}`, {});
    let isCourseCompleted = currentCourseTab === 'completed-courses' ||
        (courseProgress === null || courseProgress === void 0 ? void 0 : courseProgress.hasOwnProperty('consumedAt'));
    if (isCourseCompleted) {
        return true;
    }
    const completedLessons = (_a = Object.values(courseProgress)) === null || _a === void 0 ? void 0 : _a.filter((course) => course === null || course === void 0 ? void 0 : course.hasOwnProperty('consumedAt'));
    let progressPercent = 0;
    if ((completedLessons === null || completedLessons === void 0 ? void 0 : completedLessons.length) > 0 &&
        (item === null || item === void 0 ? void 0 : item.totalLessons) > 0) {
        progressPercent = Math.round(((completedLessons === null || completedLessons === void 0 ? void 0 : completedLessons.length) /
            (item === null || item === void 0 ? void 0 : item.totalLessons)) * 100);
    }
    return progressPercent >= 100;
}
exports.isCourseCompletedFromProgress = isCourseCompletedFromProgress;
