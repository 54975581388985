"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LMSAnalyticsEngine = void 0;
const LMSEvents = {};
function LMSAnalyticsEngine(event) {
    LMSEvents[event.sessionId] = LMSEvents[event.sessionId] || [];
    LMSEvents[event.sessionId].push(event);
    return event;
}
exports.LMSAnalyticsEngine = LMSAnalyticsEngine;
